import { getFirestore } from "firebase/firestore";
import {
  collection,
  startAfter,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  orderBy,
  limit,
  runTransaction,
  Timestamp,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

const db = getFirestore();

export class memberService {
  static async getMembers(afterAt = false) {
    const members = [];
    let ref = collection(db, "members");
    let q = query(
      ref,
      where("status", "!=", "delete"),
      orderBy("status", "desc"),
      orderBy("registerTime", "desc")
    );

    if (afterAt) {
      q = query(q, startAfter(afterAt));
    }

    q = query(q, limit(20));
    const querySnapshot = await getDocs(q);
    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    querySnapshot.forEach((doc) => {
      members.push({ id: doc.id, data: doc.data() });
    });

    return { members: members, lastDoc: lastDoc, q: q };
  }
  static async getAllMembers() {
    // const querySnapshot = await getDocs(collection(db, "members"));

    // const members = [];

    // querySnapshot.forEach((doc) => {
    //   members.push({ id: doc.id, data: doc.data() });
    // });

    const members = [];

    const membersRef = collection(db, "members");
    // const q = query(
    //   membersRef,
    //   where("status", "!=", "delete"),
    //   orderBy("status", "desc"),
    //   orderBy("registerTime", "desc")
    // );
    const q = query(membersRef, orderBy("registerTime", "desc"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (!(doc.data().status && doc.data().status === "delete")) {
        members.push({ id: doc.id, data: doc.data() });
      }
    });

    return members;
  }

  static async searchMember(type, keyword) {
    console.log("觸發搜尋 firestore api");

    const members = [];

    if (type === "id") {
      const docRef = doc(db, "members", keyword);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        members.push({ id: keyword, data: docSnap.data() });
      }
    } else {
      const q = query(collection(db, "members"), where(type, "==", keyword));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        members.push({ id: doc.id, data: doc.data() });
      });
    }

    return members;
  }

  static async searchMemberByDocId(docId) {
    const docRef = doc(db, "members", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log([{ id: docSnap.id, data: docSnap.data() }])
      return [{ id: docSnap.id, data: docSnap.data() }];
    } else {
      return false;
    }
  }

  static async getOneMember(memberId) {
    const member = {};

    const result = await getDoc(doc(db, "members", memberId)).then((doc) => {
      if (doc.data()) {
        member.id = memberId;
        member.data = doc.data();
        return member;
      } else {
        return false;
      }
    });

    return result;
  }

  static async setMemberNextLevel(memberId, parentMemberId, data, t) {
    // Firestore transactions require all reads to be executed before all writes.

    if (!data.name) {
      data.name = "";
    }

    let parentMember_top1 = false;
    let parentMember_top2 = false;
    let parentMember_top3 = false;

    let ref1;
    let ref2;
    let ref3;

    // top1
    if (parentMemberId) {
      ref1 = doc(db, "members", parentMemberId);
      const parentMember_top1Doc = await t.get(ref1);
      if (!parentMember_top1Doc.exists()) {
        throw "Document 1 does not exist!";
      }
      parentMember_top1 = parentMember_top1Doc.data();

      if (parentMember_top1) {
        if (parentMember_top1.nextLevel1MembersId === undefined) {
          parentMember_top1.nextLevel1MembersId = {};
        }
        parentMember_top1.nextLevel1MembersId[memberId] = { name: data.name };
      }
    }

    // top2
    if (parentMember_top1 && parentMember_top1.parentMemberId) {
      ref2 = doc(db, "members", parentMember_top1.parentMemberId);
      const parentMember_top2Doc = await t.get(ref2);
      if (!parentMember_top2Doc.exists()) {
        throw "Document 2 does not exist!";
      }
      parentMember_top2 = parentMember_top2Doc.data();

      if (parentMember_top2) {
        if (parentMember_top2.nextLevel2MembersId === undefined) {
          parentMember_top2.nextLevel2MembersId = {};
        }
        parentMember_top2.nextLevel2MembersId[memberId] = { name: data.name };
      }
    }

    // top3
    if (parentMember_top2 && parentMember_top2.parentMemberId) {
      ref3 = doc(db, "members", parentMember_top2.parentMemberId);
      const parentMember_top3Doc = await t.get(ref3);
      if (!parentMember_top3Doc.exists()) {
        throw "Document 3 does not exist!";
      }
      parentMember_top3 = parentMember_top3Doc.data();

      if (parentMember_top3) {
        if (parentMember_top3.nextLevel3MembersId === undefined) {
          parentMember_top3.nextLevel3MembersId = {};
        }
        parentMember_top3.nextLevel3MembersId[memberId] = { name: data.name };
      }
    }

    if (parentMember_top1) {
      t.update(ref1, {
        nextLevel1MembersId: parentMember_top1.nextLevel1MembersId,
      });
    }

    if (parentMember_top2) {
      t.update(ref2, {
        nextLevel2MembersId: parentMember_top2.nextLevel2MembersId,
      });
    }

    if (parentMember_top3) {
      t.update(ref3, {
        nextLevel3MembersId: parentMember_top3.nextLevel3MembersId,
      });
    }
  }

  static async modifyMemberNextLevel(
    memberId,
    oldParentMemberId,
    newParentMemberId,
    data,
    t
  ) {
    // Firestore transactions require all reads to be executed before all writes.

    if (!data.name) {
      data.name = "";
    }

    // 需要更改的上線跟目前的上線不同才需要跑這個邏輯
    // (如果一樣的上線但是跑這個邏輯會有問題，會導致上線中的nextLevel重複出現該會員ID)
    if (oldParentMemberId !== newParentMemberId) {
      console.log("modifyMemberNextLevel");

      //STEP1. 整理 之前上線的nextLevel

      let parentMember_top1 = false;
      let parentMember_top2 = false;
      let parentMember_top3 = false;

      let ref1;
      let ref2;
      let ref3;

      // top1
      if (oldParentMemberId) {
        ref1 = doc(db, "members", oldParentMemberId);
        const parentMember_top1Doc = await t.get(ref1);
        if (!parentMember_top1Doc.exists()) {
          throw "old Document 1 does not exist!";
        }
        parentMember_top1 = parentMember_top1Doc.data();

        if (parentMember_top1 && parentMember_top1.nextLevel1MembersId) {
          delete parentMember_top1.nextLevel1MembersId[memberId];

          /*parentMember_top1.nextLevel1MembersId =
            parentMember_top1.nextLevel1MembersId.filter((el) => {
              return el != memberId;
            });
          */
        }
      }

      // top2
      if (parentMember_top1 && parentMember_top1.parentMemberId) {
        ref2 = doc(db, "members", parentMember_top1.parentMemberId);
        const parentMember_top2Doc = await t.get(ref2);
        if (!parentMember_top2Doc.exists()) {
          throw "old Document 2 does not exist!";
        }
        parentMember_top2 = parentMember_top2Doc.data();

        if (parentMember_top2 && parentMember_top2.nextLevel2MembersId) {
          delete parentMember_top2.nextLevel2MembersId[memberId];

          /*parentMember_top2.nextLevel2MembersId =
            parentMember_top2.nextLevel2MembersId.filter((el) => {
              return el != memberId;
            });
          */
        }
      }

      // top3
      if (parentMember_top2 && parentMember_top2.parentMemberId) {
        ref3 = doc(db, "members", parentMember_top2.parentMemberId);
        const parentMember_top3Doc = await t.get(ref3);
        if (!parentMember_top3Doc.exists()) {
          throw "old Document 3 does not exist!";
        }
        parentMember_top3 = parentMember_top3Doc.data();

        if (parentMember_top3 && parentMember_top3.nextLevel3MembersId) {
          delete parentMember_top3.nextLevel3MembersId[memberId];

          /*parentMember_top3.nextLevel3MembersId =
            parentMember_top3.nextLevel3MembersId.filter((el) => {
              return el != memberId;
            });
          */
        }
      }

      //STEP2. 整理 新上線的nextLevel

      let ins_parentMember_top1 = false;
      let ins_parentMember_top2 = false;
      let ins_parentMember_top3 = false;

      let ins_ref1;
      let ins_ref2;
      let ins_ref3;

      // top1
      if (newParentMemberId) {
        ins_ref1 = doc(db, "members", newParentMemberId);
        const ins_parentMember_top1Doc = await t.get(ins_ref1);
        if (!ins_parentMember_top1Doc.exists()) {
          throw "new Document 1 does not exist!";
        }
        ins_parentMember_top1 = ins_parentMember_top1Doc.data();

        if (ins_parentMember_top1) {
          if (ins_parentMember_top1.nextLevel1MembersId === undefined) {
            ins_parentMember_top1.nextLevel1MembersId = {};
          }
          ins_parentMember_top1.nextLevel1MembersId[memberId] = {
            name: data.name,
          };
        }
      }

      // top2
      if (ins_parentMember_top1 && ins_parentMember_top1.parentMemberId) {
        ins_ref2 = doc(db, "members", ins_parentMember_top1.parentMemberId);
        const ins_parentMember_top2Doc = await t.get(ins_ref2);
        if (!ins_parentMember_top2Doc.exists()) {
          throw "new Document 2 does not exist!";
        }
        ins_parentMember_top2 = ins_parentMember_top2Doc.data();

        if (ins_parentMember_top2) {
          if (ins_parentMember_top2.nextLevel2MembersId === undefined) {
            ins_parentMember_top2.nextLevel2MembersId = {};
          }
          ins_parentMember_top2.nextLevel2MembersId[memberId] = {
            name: data.name,
          };
        }
      }

      // top3
      if (ins_parentMember_top2 && ins_parentMember_top2.parentMemberId) {
        ins_ref3 = doc(db, "members", ins_parentMember_top2.parentMemberId);
        const ins_parentMember_top3Doc = await t.get(ins_ref3);
        if (!ins_parentMember_top3Doc.exists()) {
          throw "new Document 3 does not exist!";
        }
        ins_parentMember_top3 = ins_parentMember_top3Doc.data();

        if (ins_parentMember_top3) {
          if (ins_parentMember_top3.nextLevel3MembersId === undefined) {
            ins_parentMember_top3.nextLevel3MembersId = {};
          }
          ins_parentMember_top3.nextLevel3MembersId[memberId] = {
            name: data.name,
          };
        }
      }

      // console.log(parentMember_top1.nextLevel1MembersId);
      // console.log(ins_parentMember_top1.nextLevel1MembersId);

      //STEP3. 根據資料進行寫入
      if (parentMember_top1) {
        t.update(ref1, {
          nextLevel1MembersId: parentMember_top1.nextLevel1MembersId,
        });
      }

      if (parentMember_top2) {
        t.update(ref2, {
          nextLevel2MembersId: parentMember_top2.nextLevel2MembersId,
        });
      }

      if (parentMember_top3) {
        t.update(ref3, {
          nextLevel3MembersId: parentMember_top3.nextLevel3MembersId,
        });
      }

      //..

      if (ins_parentMember_top1) {
        t.update(ins_ref1, {
          nextLevel1MembersId: ins_parentMember_top1.nextLevel1MembersId,
        });
      }

      if (ins_parentMember_top2) {
        t.update(ins_ref2, {
          nextLevel2MembersId: ins_parentMember_top2.nextLevel2MembersId,
        });
      }

      if (ins_parentMember_top3) {
        t.update(ins_ref3, {
          nextLevel3MembersId: ins_parentMember_top3.nextLevel3MembersId,
        });
      }
    } else {
      console.log("不需要跑 modify");
    }
  }

  static async insertMember(memberId, data) {
    // multiSelect比較特別, 如果取消已經選擇的上線, 會變成null值
    if (data.parentMemberId === null) {
      // data.parentMemberId = "";
      delete data.parentMemberId;
    }

    const memberDocRef = doc(db, "members", memberId);

    try {
      data.mobile = data.mobile.trim();
      await runTransaction(db, async (transaction) => {
        if (data.agent && data.agent === true && data.parentMemberId) {
          throw "無法同時設定 經紀人身份(是) 與 上線會員";
        }

        if (
          (data.role === "agentCompanyA" || data.role === "agentCompanyB") &&
          data.parentMemberId
        ) {
          throw "無法同時設定 合作經紀公司 與 上線會員";
        }

        if (!data.mobile) {
          throw "手機號碼為必填欄位";
        }

        if (await this.isExistmemberMobile(data.mobile)) {
          throw "該手機號碼已經被註冊過，請更換一組";
        }

        if (data.birthday) {
          data.birthday = new Date(data.birthday + " 00:00");
        }

        await this.setMemberNextLevel(
          memberId,
          data.parentMemberId,
          data,
          transaction
        );

        // 儲存該會員資料
        transaction.set(memberDocRef, data);
      });

      return { res: 1, msg: "新增完畢" };
    } catch (e) {
      return { res: 0, msg: e };
    }
  }

  static async updateMember(memberId, data) {
    // multiSelect比較特別, 如果取消已經選擇的上線, 會變成null值
    if (data.parentMemberId === null) {
      // data.parentMemberId = "";
      delete data.parentMemberId;
    }

    // console.log("new form data", data);

    if (!(data.status && data.status === "delete")) {
      const memberDocRef = doc(db, "members", memberId);

      try {
        data.mobile = data.mobile.trim();
        await runTransaction(db, async (transaction) => {
          // 因為有多種設定上線的狀況
          // 一開始沒有設定上線, 修改時才設定
          // 一開始有設定上線, 修改時取消設定
          // 修改時設定跟原本不同的上線
          //  ...
          //
          // 為了避免多種情況的判斷，一開始先將該會員從上線會員的nextLevel1MembersId剔除，之後再根據表單傳來的parentMemberId設定上下線關聯

          // 1. 假如一開始就有設定上線了，先解除關係
          const old_MemberDataDoc = await transaction.get(memberDocRef);
          if (!old_MemberDataDoc.exists()) {
            throw "會員不存在";
          }

          const old_MemberData = old_MemberDataDoc.data();

          if (data.agent && data.agent === true && data.parentMemberId) {
            throw "無法同時設定 經紀人身份(是) 與 上線會員";
          }

          if (
            (data.role === "agentCompanyA" || data.role === "agentCompanyB") &&
            data.parentMemberId
          ) {
            throw "無法同時設定 合作經紀公司 與 上線會員";
          }

          if (!data.mobile) {
            throw "手機號碼為必填欄位";
          }

          // 目前修改的手機號碼跟原本的號碼不同了，且改成其他會員的手機號碼，就要跳出錯誤訊息
          if (
            (await this.isExistmemberMobile(data.mobile)) &&
            old_MemberData.mobile != data.mobile
          ) {
            throw "該手機號碼已經被註冊過，請更換一組";
          }

          // 如果上線有變更，統一用modifyMemberNextLevel，這個function也可以重新配置Map內的name值 (假如有改名的話重新配置)
          if (old_MemberData.parentMemberId !== data.parentMemberId) {
            await this.modifyMemberNextLevel(
              memberId,
              old_MemberData.parentMemberId,
              data.parentMemberId,
              data,
              transaction
            );
          } else {
            console.log("不需要跑 modify");
            // 沒有變更上線 且 有改名字才跑這段 （假如有更改會員的名字則修改上線的nextLevelMember內的資訊）
            // 勿把 modifyMemberNextLevel 與 setMemberNextLevel 寫一起，因為transaction需要 all reads to be executed before all writes
            if (old_MemberData.name !== data.name) {
              console.log("name different re set MemberNextlevel info");
              await this.setMemberNextLevel(
                memberId,
                data.parentMemberId,
                data,
                transaction
              );
            }
          }

          // console.log("old", old_MemberData);
          // console.log("new", data);

          // 2. 儲存該會員資料
          transaction.set(memberDocRef, data);
        });

        return { res: 1, msg: "修改完畢" };
      } catch (e) {
        return { res: 0, msg: e };
      }
    } else {
      return { res: 0, msg: "已停用的會員，無法修改" };
    }
  }

  /**
   * 將會員的status設定為delete, 並將該會員從上1~3層的 nextLevel?MembersId中去除
   * @param {*} id
   */
  static async deleteMember(id) {
    const memberDocRef = doc(db, "members", id);

    try {
      await runTransaction(db, async (transaction) => {
        const memberDoc = await transaction.get(memberDocRef);
        if (!memberDoc.exists()) {
          throw "Document does not exist!";
        }

        const member = memberDoc.data();
        // console.log(id)
        // console.log(member)

        if (member.parentMemberId) {
          await this.modifyMemberNextLevel(
            id,
            member.parentMemberId,
            "",
            member,
            transaction
          );
        } else {
          console.log("No 上線");
        }

        transaction.update(memberDocRef, { status: "delete" });
      });
      //alert("已完成停用");
    } catch (e) {
      alert(e);
    }
  }

  /**
   * 將已經停用的會員重新啟用
   * @param {*} id
   */
  static async statusNormalMember(id) {
    const memberDocRef = doc(db, "members", id);

    try {
      await runTransaction(db, async (transaction) => {
        const memberDoc = await transaction.get(memberDocRef);
        if (!memberDoc.exists()) {
          throw "Document does not exist!";
        }

        const member = memberDoc.data();
        // console.log(id);
        // console.log(member);

        if (member.parentMemberId) {
          await this.setMemberNextLevel(
            id,
            member.parentMemberId,
            member,
            transaction
          );
        } else {
          console.log("No 上線");
        }

        transaction.update(memberDocRef, { status: "normal" });
      });
      //alert("已完成重啟該會員");
    } catch (e) {
      alert(e);
    }
  }

  static async getMembersSelectOptions_withOutOne(withoutId = false) {
    const members = await this.getAllMembers();
    const options = [];

    // 過濾掉目前修改頁面的UserId
    members.filter((el) => {
      if (!withoutId || (withoutId && el.id != withoutId)) {
        let addText = "";
        let addMobile = "";

        if (el.data.mobile) {
          addMobile = " - " + el.data.mobile;
        }

        if (!(el.data.status && el.data.status === "delete")) {
          options.push({
            value: el.id,
            label: el.data.name + addMobile + addText,
            status: el.data.status,
          });
        }
      }
    });

    return options;
  }

  /*
   * 根據該會員的下線數回傳對應的分潤比例
   * 目前分潤比例有分 basic與gian，需達成的條件為 >= numberOfPeople
   */
  static async getMemberRate(num, prefix = "gx_") {
    const docRef = doc(db, "setting", prefix + "profitSharingRatio");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      let result = {};

      // console.log('gain', data.gain);
      // console.log('basic', data.basic);

      if (num >= data.gain.numberOfPeople) {
        result = {
          rate1: data.gain.rate1,
          rate2: data.gain.rate2,
          rate3: data.gain.rate3,
        };
      } else if (num >= data.basic.numberOfPeople) {
        result = {
          rate1: data.basic.rate1,
          rate2: data.basic.rate2,
          rate3: data.basic.rate3,
        };
      } else if (num <= 0) {
        result = {
          rate1: 0,
          rate2: 0,
          rate3: 0,
        };
      }

      // console.log('result', result);
      // console.log('---------------------');

      return result;
    } else {
      // doc.data() will be undefined in this case
      return false;
    }
  }

  static async unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]["children"] = [];
    }

    for (var id in mappedArr) {
      // mappedArr.hasOwnProperty(id) 原本寫法, 但是過不了eslint
      if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          mappedArr[mappedElem["parentid"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  static async getTreeMember(memberId) {
    const citiesRef = collection(db, "members");

    const t = [];
    const member = await this.getOneMember(memberId);

    let nowNodes = [member.id];

    t.push({
      id: member.id,
      parentid: "", //根都設定空
      name: member.data.name,
      status: member.data.status,
    });

    let level = 1;
    while (level <= 3) {
      let tmpNodes = [];

      for (let i = 0; i < nowNodes.length; i++) {
        const q = query(citiesRef, where("parentMemberId", "==", nowNodes[i]));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          tmpNodes.push(doc.id);

          t.push({
            id: doc.id,
            parentid: doc.data().parentMemberId,
            name: doc.data().name,
            status: doc.data().status,
          });
        });
      }

      nowNodes = tmpNodes;
      tmpNodes = [];

      level++;
    }

    return this.unflatten(t);
  }

  static async getMemberBonusDetail(memberId, year, month) {
    const brokerageFeesRef = doc(
      db,
      "members",
      memberId,
      "brokerageFees",
      `${year}${month}`
    );

    const brokerageFeesSnap = await getDoc(brokerageFeesRef);

    if (brokerageFeesSnap.exists()) {
      const data = brokerageFeesSnap.data();

      data.L1BonusDetailTotal = 0;
      data.L2BonusDetailTotal = 0;
      data.L3BonusDetailTotal = 0;

      if (Object.keys(data.L1BonusDetail).length > 0) {
        for (let k in data.L1BonusDetail) {
          data.L1BonusDetailTotal += data.L1BonusDetail[k];
        }
      }

      if (Object.keys(data.L2BonusDetail).length > 0) {
        for (let k in data.L2BonusDetail) {
          data.L2BonusDetailTotal += data.L2BonusDetail[k];
        }
      }

      if (Object.keys(data.L3BonusDetail).length > 0) {
        for (let k in data.L3BonusDetail) {
          data.L3BonusDetailTotal += data.L3BonusDetail[k];
        }
      }

      return data;
    } else {
      return false;
    }
  }

  /**
   * 抓取會員的打卡資訊
   * @param {*} memberId
   * @param {*} year
   * @param {*} month
   * @returns
   */
  static async getMemberCheckIn(memberId, year, month) {
    month = ("0" + month).slice(-2);

    const checkInRef = doc(
      db,
      "members",
      memberId,
      "check-in",
      `${year}${month}`
    );

    const checkInSnap = await getDoc(checkInRef);

    if (checkInSnap.exists()) {
      const data = checkInSnap.data();
      return data.time;
    } else {
      return false;
    }
  }

  static async getMemberTransaction(memberId) {
    const result = [];

    const ref = collection(db, "members", memberId, "transaction");
    const q = query(ref, orderBy("entryTime", "desc"), limit(20)); // test -> , where("name", "==", "aaaaaaaa")

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());

      result.push({ id: doc.id, data: doc.data() });
    });

    return result;
  }

  static async transaction(transactionData, memberId) {
    // create member ref
    const memberRef = doc(db, "members", memberId);

    // create member's transaction ref
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate();
    const transactionId =
      String(year) +
      String(month) +
      String(date) +
      "-" +
      now.getTime() +
      "-" +
      Math.floor(Math.random() * 100000);
    const transactionRef = doc(
      db,
      "members",
      memberId,
      "transaction",
      transactionId
    );

    try {
      // 開始跑交易流程
      const result = await runTransaction(db, async (transaction) => {
        // -------------- step1 會員集合balance欄位 調整

        // 資料驗證
        if (
          typeof transactionData.transactionBonus !== "number" ||
          transactionData.transactionBonus <= 0
        ) {
          return { result: 0, msg: "請輸入大於0的G幣金額" };
        }

        if (transactionData.transactionBonus % 1 !== 0) {
          return { result: 0, msg: "G幣金額請輸入整數" };
        }

        const sfDoc = await transaction.get(memberRef);
        if (!sfDoc.exists()) {
          return { result: 0, msg: "查無會員" };
        }

        // 取得會員資料
        const member = sfDoc.data();

        let successMsg;

        // 判斷是存入 or 提出
        if (transactionData.transactionDoType === "in") {
          // 存入佣金
          if (member.balance) {
            member.balance += transactionData.transactionBonus;
          } else {
            member.balance = transactionData.transactionBonus;
          }

          successMsg = `已成功存入 ${transactionData.transactionBonus}`;
        } else if (transactionData.transactionDoType === "out") {
          if (
            member.balance &&
            member.balance >= transactionData.transactionBonus
          ) {
            member.balance -= transactionData.transactionBonus;
          } else {
            return { result: 0, msg: "提領失敗，提領的G幣大於目前的餘額" };
          }

          successMsg = `已成功提出 ${transactionData.transactionBonus}`;
        }

        transaction.update(memberRef, { balance: member.balance });

        // -------------- step2 會員transaction子集合 寫入

        const auth = getAuth();
        const user = auth.currentUser;

        const trans = {
          balance: member.balance,
          bonus: transactionData.transactionBonus,
          doType: transactionData.transactionDoType,
          entryTime: new Date(),
          transactionType: transactionData.transactionTypeSelected,
          remark: transactionData.transactionRemark,
          creator: user.email,
          memberDocId: memberId,
        };

        if (member.name) {
          trans["member_name"] = member.name;
        }

        if (member.member_id) {
          trans["member_id"] = member.member_id;
        }

        if (member.store) {
          trans["member_store"] = member.store;
        }

        //增加編號藝名
        if (member.numberStageName) {
          trans["member_numberStageName"] = member.numberStageName;
        }

        transaction.set(transactionRef, trans);

        // 轉換時間格式，這樣新增完馬上顯示才不會有問題
        trans.entryTime = Timestamp.fromDate(trans.entryTime);

        return {
          result: 1,
          msg: successMsg,
          balance: member.balance,
          totalBonus: member.totalBonus,
          trans: trans,
          // transId: transRef.id,
        };
      });

      return result;
    } catch (e) {
      return { result: 0, msg: e };
    }
  }

  static async hasChild(memberId) {
    const ref = collection(db, "members");
    const q = query(ref, where("parentMemberId", "==", memberId));
    const querySnapshot = await getDocs(q);

    // console.log(querySnapshot.docs.length);
    if (querySnapshot.docs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 以手機號碼查詢會員是否存在
   * @param {*} mobile
   * @returns
   */
  static async isExistmemberMobile(mobile) {
    const trimmedMobile = mobile.trim();
    const ref = collection(db, "members");
    const q = query(ref, where("mobile", "==", trimmedMobile));
    const querySnapshot = await getDocs(q);

    // console.log(querySnapshot.docs.length);
    if (querySnapshot.docs.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
